import React, { useEffect, useState } from "react";

export default function ScrollIndicator() {
  const [scrollPercentage, setScrollPercentage] = useState(0);

  const handleScroll = () => {
    const scrollTop = document.documentElement.scrollTop;
    const scrollHeight =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrollPercent = (scrollTop / scrollHeight) * 100;
    setScrollPercentage(scrollPercent);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className="fixed top-0 left-0 w-full h-1 z-50"
      style={{ background: "rgb(28, 27, 25)" }}
    >
      <div
        className="h-1"
        style={{
          width: `${scrollPercentage}%`,
          background: "rgb(167, 243, 208)",
        }}
      ></div>
    </div>
  );
}
