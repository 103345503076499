export default {
  nav: {
    logo: "LM",
    links: [
      { text: "About me", to: "mywork" },
      { text: "Contact", to: "mycontact" },
    ],
  },
  header: {
    img: process.env.PUBLIC_URL + "/assets/profile_picture.jpg",
    imgPlaceholder: process.env.PUBLIC_URL + "/assets/profile_picture.jpg",
    text: ["Hi!", "It's me Luis Menendez.", "I am "],
    typical: [
      "Mobile Developer. 📱 ",
      2000,
      "web developer. 💻 ",
      2000,
      "FrontEnd Developer. 🌐 ",
      2000,
      "Backend Developer. 🔐",
      2000,
      "Serverless Developer. 🚀 ",
      2000,
      "video games player.🎮",
      2000,
      "Backend Developer. 🖥️ ",
      2000,
      "Dog dad.🐶",
      2000,
      "Backpacker. 🗺️",
      2000,
      "Dreamer. 🦄",
      2000,
      "Guatemalan. 🔥",
      6000,
    ],
    btnText: "Take a look",
  },
  work: {
    title: "About me?",
    img: process.env.PUBLIC_URL + "/assets/projects.png",
    imgPlaceholder: "content.work.img",
    projectName: "Biography",
    gitHubContribution: "GitHub Contributions",
    desc: "Hello there! I'm a seasoned software developer with a passion for exploration and continuous learning. At 34 years young, I thrive on adventures, often opting for backpacking journeys over Netflix binges. You'll usually find me engrossed in a captivating book, taking a leisurely ride, or delving into the realms of new knowledge.\nMy journey in the IT industry spans over a decade, but it was in 2019 when I stumbled upon React.js that my fascination truly ignited. The endless possibilities it offers in crafting innovative solutions captivated me from the start.\n\nI'm on a mission to showcase my expertise through a collection of projects centered around React, the dynamic MERN stack, and Python, often intertwined with other cutting-edge digital technologies. Join me as I embark on this exciting journey of creation and discovery!",
  },
  portfolio: {
    title: "Portfolio",
    projects: [
      {
        src: process.env.PUBLIC_URL + "/assets/nombolo.png",
        altText: "APP",
        caption: "Nombolo APP",
        header:
          "Mobile Developer - React Native: Expo, AWS, Lambda, Dynamo, Cognito, SNS, RestAPI",
        key: "1",
      },
      {
        src: process.env.PUBLIC_URL + "/assets/mytheresa.png",
        altText: "APP",
        caption: "Mytheresa APP",
        header:
          "Mobile Developer - React Native: Expo, AWS, Lambda, Dynamo, Cognito, SNS, RestAPI",
        key: "1",
      },
      {
        src: process.env.PUBLIC_URL + "/assets/lk1.png",
        altText: "System",
        caption: "LikeInc",
        header:
          "Fullstack - Campaigns System: React, Node, AWS: Lambda, Dynamo, Cognito, SNS, RestAPI Chartjs",
        key: "2",
      },

      {
        src: process.env.PUBLIC_URL + "/assets/chatql.png",
        altText: "ChatQL",
        caption: "ChatQL",
        header:
          "Fullstack - Chat & Queue Managment: React, Aws, Dynamo, GraphQL, Cognito and  Anonymous login for embebed on websites",
        key: "4",
      },
      {
        src: process.env.PUBLIC_URL + "/assets/lk2.png",
        altText: "System",
        caption: "LikeInc",
        header:
          "Fullstack - Campaigns System: React, Node, AWS: Lambda, Dynamo, Cognito, SNS, RestAPI, Bucket S3, Chartjs",
        key: "5",
      },
      {
        src: process.env.PUBLIC_URL + "/assets/lk3.png",
        altText: "System",
        caption: "LikeInc",
        header: "GeoPoint Api, Pixel to Meter Convertor, multiple Lat and Long",
        key: "6",
      },
      {
        src: process.env.PUBLIC_URL + "/assets/chatql2.jpg",
        altText: "ChatQL",
        caption: "ChatQL",
        header: "Chat for embebed on websites",
        key: "7",
      },
      {
        src: process.env.PUBLIC_URL + "/assets/chatql2.jpg",
        altText: "ChatQL",
        caption: "ChatQL",
        header: "Chat for embebed on websites",
        key: "7",
      },
      {
        src: process.env.PUBLIC_URL + "/assets/flutterapp.gif",
        altText: "Flutter App",
        caption: "Flutter App",
        header: "Flutter App + Node.js + Express + Firebase + ai + scrapping",
        key: "8",
      },
    ],
  },
  stack: {
    title: "Fullstack Developer",
    tech: [
      {
        name: "AWS",
        value: "90",
        attributes: [
          { name: "EC2", value: "EC2", score: 80 },
          { name: "S3", value: "S3", score: 90 },
          { name: "RDS", value: "RDS", score: 80 },
          { name: "CloudFront", value: "CloudFront", score: 80 },
          { name: "Lambda", value: "Lambda", score: 90 },
          { name: "SNS", value: "SNS", score: 90 },
          { name: "Route53", value: "Route53", score: 80 },
          { name: "DynamoDB", value: "DynamoDB", score: 90 },
          { name: "CloudWatch", value: "CloudWatch", score: 80 },
          { name: "Amplity", value: "Amplify", score: 80 },
          { name: "AppSync", value: "AppSync", score: 80 },
        ],
        img: process.env.PUBLIC_URL + "/assets/aws.png",
        alt: "aws",
        years: "5+",
      },

      {
        name: "React",
        value: "90",
        years: "5+",
        attributes: [
          { name: "React", value: "React", score: 100 },
          { name: "React Router", value: "React Router", score: 100 },
          { name: "Redux", value: "Redux", score: 100 },
          { name: "React Hooks", value: "React Hooks", score: 100 },
          { name: "React Context", value: "React Context", score: 100 },
          { name: "React Redux", value: "React Redux", score: 100 },
        ],
        img: process.env.PUBLIC_URL + "/logo512.png",
        alt: "react",
      },
      {
        name: "Flutter",
        value: "90",
        years: "3+",
        attributes: [
          { name: "Flutter", value: "Flutter", score: 100 },
          { name: "Flutter Web", value: "Flutter Web", score: 100 },
          { name: "Flutter Desktop", value: "Flutter Desktop", score: 50 },
          { name: "Flutter Mobile", value: "Flutter Mobile", score: 100 },
        ],
        img: process.env.PUBLIC_URL + "/assets/flutter.png",
        alt: "react",
      },
      {
        name: "Node.js",
        value: "75",
        years: "7+",
        attributes: [
          { name: "Node.js", value: "Node.js", score: 90 },
          { name: "Express", value: "Express", score: 90 },
          { name: "Axios", value: "Axios", score: 100 },
          { name: "Mongoose", value: "Mongoose", score: 100 },
        ],
        img: process.env.PUBLIC_URL + "/assets/node.png",
        alt: "node",
      },
      {
        name: "Python",
        value: "75",
        years: "3+",
        attributes: [
          { name: "Python", value: "Python", score: 90 },
          { name: "Flask", value: "Flask", score: 90 },
          { name: "Django", value: "Django", score: 90 },
          {
            name: "Django Rest Framework",
            value: "Django Rest Framework",
            score: 90,
          },
        ],
        img: process.env.PUBLIC_URL + "/assets/python.png",
        alt: "python",
      },
      {
        name: "MongoDB",
        value: "90",
        years: "5+",
        attributes: [
          { name: "MongoDB", value: "MongoDB", score: 100 },
          { name: "Mongoose", value: "Mongoose", score: 100 },
          { name: "MongoDB Compass", value: "MongoDB Compass", score: 100 },
          { name: "MongoDB Atlas", value: "MongoDB Atlas", score: 100 },
        ],
        img: process.env.PUBLIC_URL + "/assets/mongo.png",
        alt: "mongo",
      },
      {
        name: "Firebase",
        value: "90",
        years: "5+",
        attributes: [
          { name: "Firebase", value: "Firebase", score: 100 },
          { name: "Firestore", value: "Firestore", score: 100 },
          { name: "Firebase Auth", value: "Firebase Auth", score: 100 },
          { name: "Firebase Storage", value: "Firebase Storage", score: 100 },
          {
            name: "Firebase Cloud Functions",
            value: "Firebase Cloud Functions",
            score: 100,
          },
          { name: "Firebase Hosting", value: "Firebase Hosting", score: 100 },
          {
            name: "Firebase Analytics",
            value: "Firebase Analytics",
            score: 100,
          },
        ],
        img: process.env.PUBLIC_URL + "/assets/firebase.png",
        alt: "firebase",
      },
      {
        img: process.env.PUBLIC_URL + "/assets/html5-css3-js.png",
        alt: "HTML5-CSS3-JS",
        name: "HTML5",
        value: "100",
        years: "10+",
        attributes: [
          { name: "Semantic HTML", value: "Semantic HTML", score: 100 },
          { name: "Canvas API", value: "Canvas API", score: 90 },
          { name: "WebSockets", value: "WebSockets", score: 80 },
          { name: "Service Workers", value: "Service Workers", score: 90 },
          { name: "Geolocation API", value: "Geolocation API", score: 85 },
          { name: "Web Storage", value: "Web Storage", score: 90 },
          { name: "IndexedDB", value: "IndexedDB", score: 85 },
          { name: "Forms & Validation", value: "Forms", score: 100 },
          { name: "HTML Media", value: "HTML Media", score: 80 },
          { name: "Drag and Drop", value: "Drag and Drop", score: 80 },
        ],
      },
      {
        name: "Next.js",
        value: "100",
        years: "5+",
        attributes: [
          { name: "Next.js", value: "Next.js", score: 100 },
          { name: "SSR", value: "SSR", score: 100 },
          { name: "ISR", value: "ISR", score: 80 },
          { name: "SSG", value: "SSG", score: 100 },
        ],
        img: process.env.PUBLIC_URL + "/assets/next.png",
        alt: "Next.js",
      },
      {
        name: "Nest.js",
        value: "100",
        years: "3+",
        attributes: [
          { name: "Nest.js", value: "Nest.js", score: 100 },
          { name: "TypeORM", value: "TypeORM", score: 100 },
          { name: "Passport", value: "Passport", score: 100 },
          { name: "JWT", value: "JWT", score: 100 },
        ],
        img: process.env.PUBLIC_URL + "/assets/nest.png",
        alt: "Nest.js",
      },
      {
        name: "React Native",
        years: "5+",
        value: "100",
        attributes: [
          { name: "React Native CLI", value: "React Native CLI", score: 100 },
          { name: "React Navigation", value: "React Navigation", score: 100 },
          { name: "React Native Reanimated", value: "Reanimated", score: 90 },
          { name: "Native Modules", value: "Native Modules", score: 80 },
          { name: "Hermes Engine", value: "Hermes Engine", score: 80 },
          { name: "Animations", value: "Animations", score: 90 },
          { name: "State Management", value: "State Management", score: 90 },
          { name: "Performance Optimization", value: "Performance", score: 85 },
          { name: "Custom UI Components", value: "Custom UI", score: 90 },
          {
            name: "Integration with Native Code",
            value: "Native Integration",
            score: 80,
          },
        ],
        img: process.env.PUBLIC_URL + "/assets/reactnative.png",
        alt: "React Native CLI",
      },
      {
        name: "Expo",
        years: "5+",
        value: "100",
        attributes: [
          { name: "Expo Go", value: "Expo Go", score: 100 },
          { name: "Expo CLI", value: "Expo CLI", score: 100 },
          { name: "Expo Router", value: "Expo Router", score: 90 },
          { name: "EAS Build", value: "EAS Build", score: 90 },
          { name: "EAS Update", value: "EAS Update", score: 90 },
          { name: "Expo SDK", value: "Expo SDK", score: 100 },
          {
            name: "Push Notifications",
            value: "Push Notifications",
            score: 80,
          },
          { name: "Camera API", value: "Camera API", score: 80 },
          { name: "Maps API", value: "Maps API", score: 80 },
          { name: "AuthSession", value: "AuthSession", score: 90 },
        ],
        img: process.env.PUBLIC_URL + "/assets/expo.png",
        alt: "Expo",
      },
      {
        name: "AI & ML",
        value: "90",
        years: "2+",
        attributes: [
          {
            name: "OpenAI GPT-4",
            value: "ChatGPT API",
            score: 100,
          },
          {
            name: "DALL·E",
            value: "Image Generation",
            score: 90,
          },
          {
            name: "Gemini",
            value: "Google AI Gemini",
            score: 80,
          },
          {
            name: "Bing AI",
            value: "Microsoft Bing AI",
            score: 85,
          },
          {
            name: "Hugging Face",
            value: "Hugging Face Transformers",
            score: 90,
          },
          {
            name: "Stable Diffusion",
            value: "Image Generation",
            score: 80,
          },
          {
            name: "LangChain",
            value: "LangChain Framework",
            score: 85,
          },
          {
            name: "Vector Databases",
            value: "Pinecone / Weaviate",
            score: 80,
          },
          {
            name: "Prompt Engineering",
            value: "Advanced Prompting Techniques",
            score: 100,
          },
          {
            name: "Speech-to-Text",
            value: "Whisper API",
            score: 85,
          },
          {
            name: "Text-to-Speech",
            value: "Google TTS / ElevenLabs",
            score: 85,
          },
          {
            name: "Fine-tuning",
            value: "Custom Model Training",
            score: 80,
          },
          {
            name: "AI Chatbots",
            value: "End-to-End Bot Development",
            score: 100,
          },
          {
            name: "Computer Vision",
            value: "Image Recognition / OpenCV",
            score: 70,
          },
          {
            name: "Sentiment Analysis",
            value: "NLP Sentiment Analysis",
            score: 90,
          },
          {
            name: "Recommendation Systems",
            value: "Collaborative Filtering",
            score: 80,
          },
          {
            name: "Google Vertex AI",
            value: "Vertex AI Tools",
            score: 70,
          },
          {
            name: "AWS AI Services",
            value: "SageMaker / Rekognition",
            score: 75,
          },
          {
            name: "Azure AI",
            value: "Cognitive Services",
            score: 80,
          },
        ],
        img: process.env.PUBLIC_URL + "/assets/ai.jpg",
        alt: "AI & Machine Learning",
      },
    ],
    desc: `The STACK than i use is based in React, AWS, Node.js, MongoDB, Python and Firebase, of course HTML5 and CSS3 is a most;  I ❤️Serverless.`,
  },
  contact: {
    title: "Ping Me!",
    desc: "You can contact me in the Following networks.",
    socials: [
      {
        alt: "facebook",
        img: process.env.PUBLIC_URL + "/assets/facebook.png",
        link: "http://www.facebook.com/luis.menendez1",
      },
      {
        alt: "github",
        img: process.env.PUBLIC_URL + "/assets/github.png",
        link: "https://github.com/menendezluis/",
      },
      {
        alt: "link",
        img: process.env.PUBLIC_URL + "/assets/link.png",
        link: "https://www.linkedin.com/in/luis-menendez-a2437956/",
      },
      {
        alt: "gmail",
        img: process.env.PUBLIC_URL + "/assets/gmail.png",
        link: "luis@luismenendez.dev",
      },
    ],
    img: process.env.PUBLIC_URL + "/assets/miniprofile.png",
    imgPlaceholder: process.env.PUBLIC_URL + "/assets/miniprofile.png",
  },
};
