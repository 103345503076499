import React from "react";
import Contact from "./components/Contact";
import Header from "./components/Header";
import Navigation from "./components/Navigation";
import Stack from "./components/Stack";
import Work from "./components/Work";
import ProgressBar from "./components/ProgressBar";

import Slider from "./components/Slider";
import { GitHubContributions } from "./components/GitHubContributions";

function App() {
  return (
    <>
      <ProgressBar />
      <Navigation />
      <Header />
      <Work />
      <GitHubContributions />

      <Stack />
      <Slider />
      <Contact />
    </>
  );
}

export default App;
