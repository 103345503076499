import React, { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
} from "recharts";
import content from "../content";

export default function Stack() {
  const [selectedTech, setSelectedTech] = useState(null);

  const handleTechClick = (tech) => {
    setSelectedTech(tech);
  };

  return (
    <div
      className="min-h-screen flex items-center justify-center flex-col"
      style={{
        background: "#eee",
      }}
    >
      <h1 className="text-5xl font-dosis font-bold mb-10">Stack I Use</h1>

      <div>
        <div className="flex flex-wrap justify-center">
          {content.stack.tech.map((tech, index) => (
            <span
              key={index}
              onClick={() => handleTechClick(tech)}
              className={`h-40 w-40 bg-white shadow-xl ml-2 rounded-full flex justify-center items-center p-5 m-2 relative cursor-pointer ${
                index % 2 !== 0 ? "animate-bounce" : "animate-bounceFirst"
              }`}
            >
              <p className="absolute top-0 text-white w-10 h-10 text-center rounded-full flex items-center justify-center bg-red-500 -mr-24">
                {tech.years}
              </p>
              <LazyLoadImage effect="blur" src={tech.img} alt={tech.alt} />
              <h1 className="absolute bottom-0 text-xl font-dosis font-bold">
                {tech.name}
              </h1>
            </span>
          ))}
        </div>
      </div>

      {/* Mostrar el gráfico si se selecciona una tecnología */}
      {selectedTech && (
        <div className="mt-10 bg-white shadow-xl p-10 w-full rounded-lg">
          <h2 className="text-3xl font-bold mb-5">{selectedTech.name}</h2>
          <ResponsiveContainer width="100%" height={400}>
            <BarChart
              data={selectedTech.attributes}
              layout="vertical" // Cambia el layout a vertical para barras horizontales
              margin={{ top: 20, right: 30, left: 10, bottom: 20 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <YAxis
                type="category"
                dataKey="name"
                width={100} // Ajusta el espacio para los nombres largos
                tick={{ fontSize: 14 }}
              />
              <XAxis type="number" />
              <Tooltip />
              <Bar dataKey="score" fill="#8884d8" barSize={20} />
            </BarChart>
          </ResponsiveContainer>
          <p className="mt-5 text-lg font-dosis">
            Years of experience: {selectedTech.years}
          </p>
        </div>
      )}

      <p className="mt-10 mb-10 w-11/12 md:max-w-xl text-center inline-block text-xl md:text-2xl font-dosis">
        {content.stack.desc}.
      </p>
    </div>
  );
}
